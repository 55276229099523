$( document ).ready(function() {

  // Add class to body on hamburger click
  $('.advance-arrow').on('click', function(e) {

    e.preventDefault();

    var nextSection  = $(this).closest('.kc-row-container').parent().next('.kc_row');
    var bannerHeight = $('.banner').data('scrolled-size') ? $('.banner').data('scrolled-size') : $('.banner').height();
    bannerHeight = winWidth < 768 ? $('.banner').height() : bannerHeight;
    var scrollHeight = nextSection.offset().top - bannerHeight;
    
    $('html,body').animate({
      scrollTop: scrollHeight
    }, 'slow');

  });

});