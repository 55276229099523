$( document ).ready(function() {

  if ( $('#disclaimer') ) {
    
    $('.disclaimer--banner-button').on( 'click', function (e) {

      e.preventDefault();

      window.location = '/disclaimer';

    });

    $('.disclaimer--terms-button').on( 'click', function (e) {

      e.preventDefault();

      var date = new Date();
      date.setTime(date.getTime() + (1000 * 60 * 60 * 24 * 7));
      Cookies.set("disclaimer-accepted", "1", { expires: date, path: '/' });

      if ( $('body').hasClass('disclaimer-interstitial') ) {
        var redirect = getUrlParameter('redirect');
        window.location = '/' + redirect;
      } else {
        $('#disclaimer').addClass( 'disclaimer-hide' );
        $('body').removeClass('disclaimer-popup-showing');
      }

    });

  }

});