$( document ).ready(function() {

  var winWidth = $(window).width();

  // Detect if banner has been scrolled past
  var navScrolled = function() {

    var $window    = $(window);
    var docViewTop = 83;
    var nav        = $('.banner');
    var elemTop    = $window.scrollTop();

    if ( elemTop > docViewTop ) {
      nav.addClass('scrolled');
    } else {
      nav.removeClass('scrolled');
    }

  };

  navScrolled();

  $( window ).scroll( function(e) {
    navScrolled();
  });

  // Detect if element is inview
  var inView = function ( elements ) {

    var $window    = $(window);
    var docViewTop = $window.scrollTop() + $window.height() - ( $window.height() / 10 );

    elements.each( function( index ) {

      if ( !$(this).hasClass('over') ) {

        var elemTop = $(this).offset().top;

        if ( elemTop < docViewTop ) {
          $(this).addClass('over');
        }

      }

    });

  };

  inView( $('.inview') );

  $( window ).scroll( function(e) {
    inView( $('.inview') );
  });

  // Set fixed row elements
  if ( $('.fixed-row-element').length > 0 ) {

    $('.fixed-row-element').each( function(index, value) {

      var $this = $(this);
      $(this).parent().css('position','relative');
      var elHeight     = $(this).innerHeight();
      var rowContainer = $(this).closest('.kc-row-container');
      var winScrollTop = $(window).scrollTop();
      var rowOffsetTop = rowContainer.offset().top;
      var rowHeight    = $(rowContainer).height();
      var elOffsetTop    = $(this).offset().top;

      if ( elHeight >= rowHeight ) {
        rowContainer.css( 'height', elHeight + 'px' );
      }

      $(this).css({
        'top'      : elOffsetTop + 'px',
        'position' : 'fixed'
      });

      $( window ).scroll( function(e) {

        winScrollTop = $(window).scrollTop();

        if ( (rowHeight-winScrollTop+60) <= (elOffsetTop + elHeight) ) {
          $this.css({
            'top'      : 'auto',
            'bottom' : '0',
            'position' : 'absolute'
          });
        } else {
          $this.css({
            'top'      : elOffsetTop + 'px',
            'position' : 'fixed'
          });
        }

      });

    });

  }

  // Cookies notice dismiss
  $('.cookies-notice--close').on( 'click', function (e) {

    e.preventDefault();

    var date = new Date();
    date.setTime(date.getTime() + (1000 * 60 * 60 * 24 * 7));
    Cookies.set("cookies-notice-dismissed", "1", { expires: date, path: '/' });
    $('body').addClass('cookies-notice-dismissed');

    var label = $(this).attr('href');

    if ( typeof __gaTracker !== "undefined" && label ) {

      __gaTracker('send', 'event', {
        eventCategory : 'Cookies Notice Dismissed',
        eventAction   : 'click',
        eventLabel    : label
      });

    }

  });

});